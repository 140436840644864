import { PartialWithFieldValue, deleteField } from 'firebase/firestore';
import { Users } from '@bridebook/models';
import { IUser, IUser_Action } from '@bridebook/models/source/models/Users.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getI18n } from 'lib/i18n/getI18n';
import { setSettingsNotification } from 'lib/settings/actions';
import { IDeps } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { userFailedToEditUserDetailsAnalytics } from 'lib/weddings/actions';

export const updateUserField =
  <T extends PartialWithFieldValue<IUser>, K extends keyof T>(
    key: K,
    value: T[K],
    extraAnalytics?: Record<string, any>,
  ) =>
  ({ getState }: IDeps) => {
    const { user } = getState().users;
    if (user) {
      Users._.getById(user.id).set({
        [key]: value,
      });
    }

    return {
      type: UserActionTypes.UPDATE_USER_FIELD,
      payload: { name: key, value, extraAnalytics },
    };
  };

export const updateUserAction =
  (payload: Partial<IUser_Action>) =>
  ({ getState }: IDeps) => {
    const { user } = getState().users;
    if (user && payload) {
      Users._.getById(user.id).set({ actions: payload });
    }
    return {
      type: UserActionTypes.UPDATE_USER_FIELD,
      payload,
    };
  };

export const updateUserEmail = (value: string) => updateUserField('contacts', { email: value });

export const updateUserPhone = (value: string, extraAnalytics?: Record<string, any>) =>
  updateUserField('contacts', { phone: value }, extraAnalytics);

export const updateUserLanguage = (locale: string) => updateUserField('l10n', { locale });

export const updateUserViewerCountry = (countryCode: CountryCodes) =>
  updateUserField('l10n', { viewerCountry: countryCode });

// TODO: copy and make this work for the user photo
export const setProfilePhoto = (path: string) => {
  const value = path ? { path } : deleteField();
  return updateUserField('photo', value);
};

export const changeCorrespondenceEmailStart = ({
  fields,
  silent,
}: {
  fields: { email: string };
  silent?: boolean;
}) => ({
  type: UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_START,
  payload: { fields, silent },
});

export const changeCorrespondenceEmailSuccess =
  (silent: boolean = false, email: string = '') =>
  ({ dispatch }: IDeps) => {
    const i18n = getI18n();
    if (!silent) {
      dispatch(
        setSettingsNotification('SUCCESS', {
          message: i18n.t('settings:notification.correspondenceEmailChanged'),
        }),
      );
    }

    return {
      type: UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_SUCCESS,
      payload: { email },
    };
  };

export const changeCorrespondenceEmailError =
  (error: Error) =>
  ({ dispatch }: IDeps) => {
    dispatch(setSettingsNotification('ERROR', error));
    dispatch(userFailedToEditUserDetailsAnalytics({ error }));

    return {
      type: UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_ERROR,
    };
  };
