import {
  addWebsitePhoto,
  addWebsiteQuestionAnswer,
  fetchCountryBoundsStart,
  fetchCountryBoundsSuccess,
  removeWebsitePhoto,
  removeWebsiteQuestionAnswer,
  resetWebsiteDesignTabState,
  resetWebsiteDraft,
  setActiveWebsiteOnboardingStep,
  setWebsiteDesignCustomiseTabOpen,
  setWebsiteDesignThemeTabOpen,
  setWebsiteDirty,
  setWebsiteDraft,
  setWebsitePartners,
  setWebsitePin,
  setWebsitePublished,
  setWebsiteSectionVisibility,
  setWebsiteShouldPrefill,
  setWebsiteTheme,
  setWebsiteThemeFont,
  setWebsiteThemePalette,
  setWebsiteWeddingDate,
  stepWebsiteOnboardingBackward,
  stepWebsiteOnboardingForward,
  toggleWebsiteOnboardingModalOpen,
  updateInboxUnread,
  updatePartnerNames,
  updateWebsiteDraft,
  updateWebsitePhotos,
  updateWebsiteQuestionAnswer,
  updateWebsiteSection,
  updateWebsiteTextField,
  updateWedding,
  updateWeddingField,
  updateWeddingPreferences,
  weddingProfileSaveDate,
} from 'lib/weddings/actions';

export enum WeddingActionTypes {
  UPDATE_WEDDING = '[Wedding] Update wedding',
  UPDATE_WEDDING_FIELD = '[Wedding] Update wedding field',
  UPDATE_WEDDING_FIELD_SUCCESS = '[Wedding] Update wedding field success',
  UPDATE_WEDDING_FIELD_ERROR = '[Wedding] Update wedding field error',
  UPDATE_CURRENCY = '[Wedding] Update currency',
  SELECT_PREMIUM_PRICING_OPTION = '[Wedding] Select premium pricing option',
  SAVE_WEDDING_DATE = '[Wedding] Save wedding date',
  SAVE_WEDDING_DATE_SUCCESS = '[Wedding] Save wedding date success',
  SAVE_WEDDING_DATE_ERROR = '[Wedding] Save wedding date error',
  UPDATED_WEDDING_LOCATION_ANALYTICS = '[Analytics] [Wedding] Updated wedding location analytics',
  FETCH_PROFILE_COUNTRY_BOUNDS_START = '[Wedding] Fetch Google maps LatLngBounds from country code start',
  FETCH_PROFILE_COUNTRY_BOUNDS_SUCCESS = '[Wedding] Fetch Google maps LatLngBounds from country code success',
  FETCH_PROFILE_COUNTRY_BOUNDS_ERROR = '[Wedding] Fetch Google maps LatLngBounds from country code error',
  GLOBAL_LISTENERS_INITIALISED = '[Wedding] Global listeners initialised',
  UPDATE_INBOX_UNREAD = '[Wedding] Update inbox unread',
  UPDATE_WEDDING_PREFERENCES = '[Wedding] Update wedding preferences',
  UPDATE_PARTNER_NAMES = '[Wedding] Update partner names',
  WEDDING_PROFILE_LOADED_ANALYTICS = '[Analytics] [Wedding] Wedding profile loaded',
  WEBSITE_SET_DIRTY = '[Wedding][Website] Set dirty',
  WEBSITE_SET_SHOULD_PREFILL = '[Wedding][Website] Set should prefill',
  WEBSITE_SET_DRAFT = '[Wedding][Website] Set draft',
  WEBSITE_UPDATE_DRAFT = '[Wedding][Website] Update draft',
  WEBSITE_RESET_DRAFT = '[Wedding][Website] Reset draft',
  WEBSITE_SET_THEME = '[Wedding][Website] Set theme',
  WEBSITE_SET_THEME_FONT = '[Wedding][Website] Set theme font',
  WEBSITE_SET_THEME_PALETTE = '[Wedding][Website] Set theme palette',
  WEBSITE_SET_PIN = '[Wedding][Website] Set pin',
  WEBSITE_SET_PUBLISHED = '[Wedding][Website] Set published',
  WEBSITE_SET_PARTNERS = '[Wedding][Website] Set partners',
  WEBSITE_SET_WEDDING_DATE = '[Wedding][Website] Set wedding date',
  WEBSITE_UPDATE_SECTION = '[Wedding][Website] Update section',
  WEBSITE_SET_SECTION_VISIBILITY = '[Wedding][Website] Set section visibility',
  WEBSITE_ADD_QUESTION_ANSWER = '[Wedding][Website] Add question answer',
  WEBSITE_UPDATE_QUESTION_ANSWER = '[Wedding][Website] Update question answer',
  WEBSITE_REMOVE_QUESTION_ANSWER = '[Wedding][Website] Remove question answer',
  WEBSITE_UPDATE_TEXT_FIELD = '[Wedding][Website] Update text field',
  WEBSITE_UPDATE_PHOTOS = '[Wedding][Website] Update photos',
  WEBSITE_ADD_PHOTO = '[Wedding][Website] Add photo',
  WEBSITE_REMOVE_PHOTO = '[Wedding][Website] Remove photo',
  WEBSITE_ONBOARDING_MODAL_TOGGLE_OPEN = '[Wedding][Website][Onboarding] Toggle modal open',
  WEBSITE_ONBOARDING_SET_ACTIVE_STEP = '[Wedding][Website][Onboarding] Set active step',
  WEBSITE_ONBOARDING_STEP_BACKWARD = '[Wedding][Website][Onboarding] Step backward',
  WEBSITE_ONBOARDING_STEP_FORWARD = '[Wedding][Website][Onboarding] Step forward',
  WEBSITE_DESIGN_SET_THEME_TAB_OPEN = '[Wedding][Website][Design] Set theme tab open',
  WEBSITE_DESIGN_SET_CUSTOMISE_TAB_OPEN = '[Wedding][Website][Design] Set customise tab open',
  WEBSITE_DESIGN_RESET_TAB_STATE = '[Wedding][Website][Design] Set reset tab state',
}

export type IUpdateWeddingAction = ReturnType<ReturnType<typeof updateWedding>>;
export type IUpdateWeddingFieldAction = ReturnType<typeof updateWeddingField>;
export type IWeddingProfileSaveDateAction = ReturnType<typeof weddingProfileSaveDate>;
export type IFetchCountryBoundsStartAction = ReturnType<typeof fetchCountryBoundsStart>;
export type IFetchCountryBoundsSuccessAction = ReturnType<typeof fetchCountryBoundsSuccess>;
export type IUpdateInboxUnreadAction = ReturnType<typeof updateInboxUnread>;
export type IUpdateWeddingPreferencesAction = ReturnType<typeof updateWeddingPreferences>;
export type IUpdatePartnerNamesAction = ReturnType<typeof updatePartnerNames>;
export type ISetWebsiteDirtyAction = ReturnType<typeof setWebsiteDirty>;
export type ISetWebsiteShouldPrefillAction = ReturnType<typeof setWebsiteShouldPrefill>;
export type ISetWebsiteDraftAction = ReturnType<typeof setWebsiteDraft>;
export type IUpdateWebsiteDraftAction = ReturnType<typeof updateWebsiteDraft>;
export type IResetWebsiteDraftAction = ReturnType<typeof resetWebsiteDraft>;
export type ISetWebsiteThemeAction = ReturnType<typeof setWebsiteTheme>;
export type ISetWebsiteThemeFontAction = ReturnType<typeof setWebsiteThemeFont>;
export type ISetWebsiteThemePaletteAction = ReturnType<typeof setWebsiteThemePalette>;
export type ISetWebsitePinAction = ReturnType<typeof setWebsitePin>;
export type ISetWebsitePublishedAction = ReturnType<typeof setWebsitePublished>;
export type ISetWebsitePartnersAction = ReturnType<typeof setWebsitePartners>;
export type ISetWebsiteWeddingDateAction = ReturnType<typeof setWebsiteWeddingDate>;
export type IUpdateWebsiteSectionAction = ReturnType<typeof updateWebsiteSection>;
export type ISetWebsiteSectionVisibilityAction = ReturnType<typeof setWebsiteSectionVisibility>;
export type IAddWebsiteQuestionAnswerAction = ReturnType<typeof addWebsiteQuestionAnswer>;
export type IRemoveWebsiteQuestionAnswerAction = ReturnType<typeof removeWebsiteQuestionAnswer>;
export type IUpdateWebsiteQuestionAnswerAction = ReturnType<typeof updateWebsiteQuestionAnswer>;
export type IUpdateWebsiteTextFieldAction = ReturnType<typeof updateWebsiteTextField>;

export type IUpdateWebsitePhotosAction = ReturnType<typeof updateWebsitePhotos>;
export type IAddWebsitePhotoAction = ReturnType<typeof addWebsitePhoto>;
export type IRemoveWebsitePhotoAction = ReturnType<typeof removeWebsitePhoto>;

export type IToggleWebsiteOnboardingModalAction = ReturnType<
  typeof toggleWebsiteOnboardingModalOpen
>;
export type ISetWebsiteOnboardingModalActiveStepAction = ReturnType<
  typeof setActiveWebsiteOnboardingStep
>;
export type IStepWebsiteOnboardingBackwardAction = ReturnType<typeof stepWebsiteOnboardingBackward>;
export type IStepWebsiteOnboardingForwardAction = ReturnType<typeof stepWebsiteOnboardingForward>;
export type ISetWebsiteDesignThemeTabOpenAction = ReturnType<typeof setWebsiteDesignThemeTabOpen>;
export type ISetWebsiteDesignCustomiseTabOpenAction = ReturnType<
  typeof setWebsiteDesignCustomiseTabOpen
>;
export type IResetWebsiteDesignTabStateAction = ReturnType<typeof resetWebsiteDesignTabState>;
